import {useUserContext} from '../context/UserContext'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {useFeedbackContext} from '../context/FeedbackContext'
import {cancelSubscription, getSubscriptions, updateSubscription} from '../service/persistenceService'
import {StripeSubscriptionsContextValue} from '../context/StripeSubscriptionsContext'
import {TrackActionEvent} from '../service/SegmentService'
import {useUser} from '@clerk/clerk-react'
import {ChangePlan, ChangePlanAction, Subscription} from '../types/Stripe'
import {getDaysUntil} from '../utils/dateUtils'
import {getCurrentSubscription, isOrganizationNotPaidAfterTrial, isTrialSubscription, isValidSubscription} from '../utils/subscriptionUtils'
import {getPlanByPriceId, isUpgradedPlan} from '../utils/licenseUtils'

export const useStripeSubscriptions = (): StripeSubscriptionsContextValue => {
	const {token} = useUserContext()
	const {showFeedback} = useFeedbackContext()
	const {user} = useUser()

	const [subscriptions, setSubscriptions] = useState<Subscription[]>([])
	const [currentSubscription, setCurrentSubscription] = useState<Subscription>()
	const [isActiveSubscription, setIsActiveSubscription] = useState<boolean>(true)
	const [trialDaysLeft, setTrialDaysLeft] = useState<number>()
	const [isPausedSubscription, setIsPausedSubscription] = useState<boolean>(false)

	const getOrganizationSubscriptions = useCallback(() => {
		getSubscriptions(token).then(subscriptions => {
			setIsPausedSubscription(false)
			setSubscriptions(subscriptions)
			const currentSubscription = getCurrentSubscription(subscriptions)
			setCurrentSubscription(currentSubscription)
			if (currentSubscription) {
				const isValidCurrentSubscription = isValidSubscription(currentSubscription)
				setIsActiveSubscription(isValidCurrentSubscription)
				if (isTrialSubscription(currentSubscription)) setTrialDaysLeft(getDaysUntil(currentSubscription.trial_end!))
				if (!isValidCurrentSubscription) {
					if (isOrganizationNotPaidAfterTrial(currentSubscription)) setIsPausedSubscription(true)
				}
				TrackActionEvent('Subscription', user?.externalId ?? user?.id, {
					is_active: isValidCurrentSubscription,
					action: 'validate'
				})
			}
		}).catch(() => {
			showFeedback('Error', 'Something went wrong to load subscriptions', 'error')
			setSubscriptions([])
		})
	}, [token, showFeedback, user?.id, user?.externalId])

	const cancelCurrentSubscription = useCallback((subscriptionId: string): Promise<void> => {
		return cancelSubscription(token, subscriptionId)
			.then(() => {
				getOrganizationSubscriptions()
				showFeedback('Success', 'The subscription has been successfully cancelled', 'success')
				TrackActionEvent('Organization settings', user?.externalId ?? user?.id, {
					action: 'cancelSubscription',
				})
			})
			.catch(() => {
				showFeedback('Error', 'Something went wrong to load subscriptions', 'error')
			})
	}, [showFeedback, token, user, getOrganizationSubscriptions])

	const updateSubscriptionSeats = useCallback(async (seats: number) => {
		if (currentSubscription) {
			const currentPlan = getPlanByPriceId(currentSubscription!.plan.id)

			const changePlanInfo: ChangePlan = {
				currentPlanId: currentSubscription.plan.id,
				newPlanId: currentSubscription.plan.id,
				action: isUpgradedPlan(currentPlan, currentPlan, currentSubscription.quantity, seats) ? 'upgrade' : 'downgrade',
				seats
			}

			await updateSubscription(token, changePlanInfo).then(() => {
				getOrganizationSubscriptions()
				showFeedback('Success', 'The subscription has been successfully updated', 'success')
			}).catch(() => {
				showFeedback('Error', 'Something went wrong to update your subscription. Please, try again', 'error')
			})
		}
	}, [token, currentSubscription, showFeedback, getOrganizationSubscriptions])

	const updateSubscriptionPlan = useCallback(async (newPlanId: string, action: ChangePlanAction) => {
		if (currentSubscription) {
			const changePlanInfo: ChangePlan = {
				currentPlanId: currentSubscription.plan.id,
				newPlanId,
				action,
				seats: currentSubscription.quantity
			}

			await updateSubscription(token, changePlanInfo).then(() => {
				getOrganizationSubscriptions()
				showFeedback('Success', 'The subscription has been successfully updated', 'success')
			}).catch(() => {
				showFeedback('Error', 'Something went wrong to update your subscription. Please, try again', 'error')
			})
		}
	}, [token, currentSubscription, showFeedback, getOrganizationSubscriptions])

	useEffect(() => {
		getOrganizationSubscriptions()
	}, [getOrganizationSubscriptions])

	return useMemo(() => ({
		subscriptions,
		currentSubscription,
		isActiveSubscription,
		trialDaysLeft,
		isTrial: trialDaysLeft !== undefined,
		isPausedSubscription,
		cancelCurrentSubscription,
		updateSubscriptionSeats,
		updateSubscriptionPlan
	}), [subscriptions, currentSubscription, isActiveSubscription, trialDaysLeft, isPausedSubscription, cancelCurrentSubscription, updateSubscriptionSeats, updateSubscriptionPlan])
}
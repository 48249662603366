import {Prompt} from '../types/Prompt'
import {PromptExecutionResult} from '../types/PromptExecutionResult'
import {SearchParams} from '../types/SearchParams'
import {ListPromptsResponse} from '../types/ListPromptsResponse'
import axios, {AxiosError, AxiosRequestConfig} from 'axios'
import {Dispatch, SetStateAction} from 'react'
import {PromptChatDetail} from '../types/PromptChatDetail'
import {AIModel, AIModelID} from '../types/AiModel'
import {AmazonQResponseSettings} from '../types/AmazonQResponseSettings'
import {UsageStatistic} from '../types/UserUsageStatistic'
import {DateRange} from 'rsuite/DateRangePicker'
import {UserInfo} from '../types/UserInfo'
import {UserOrganization} from '../types/UserOrganization'
import {waitTime} from '../utils/timeoutHelpers'
import {UserAuditLog} from '../types/UserAuditLog'
import {Chat} from '../types/Chat'
import {ChatMessageStored} from '../types/ChatMessageStored'
import {Organization} from '../types/Organization'
import {retryAsyncCallback} from '../utils/asyncUtils'
import {AdminEmail} from '../types/AdminEmail'
import {FormatWarning, TopicWarning, WordWarning} from '../types/Warning'
import {GroupMember} from '../types/GroupMember'
import {Group} from '../types/Group'
import {PromptExecutionParams} from '../types/PromptExecutionParams'
import {ChangePlan, Subscription} from '../types/Stripe'
import {Budget, ClosedBudget} from '../types/Budget'
import {AnalyticsEvent} from '../types/AnalyticsEvent'
import {TaxesInfo} from '../context/PaymentContext'
import {AwsMarketplaceCustomerData} from '../types/AwsMarketplaceCustomerData'
import {CustomerAddress} from '../types/CustomerData'
import {SubscriptionCreationResult} from '../types/SubscriptionCreationResult'
import {FileId, FileMetadata} from "../types/File"

const fetch = require('fetch-retry')(global.fetch)

const executePromptUrl = process.env.REACT_APP_API_EXECUTE_PROMPT
const client = axios.create({
	baseURL: process.env.REACT_APP_API_DOMAIN_NAME
})

/**
 * Generate basic request config
 * @param token user authentication token
 * @param signal signal object to communicate with a DOM request and abort it if required via AbortController.
 * @return object with the config
 */
const generateConfig = (token: string, signal?: AbortSignal): AxiosRequestConfig => ({
	headers: {
		'authorization': `Bearer ${token}`,
	},
	signal
})

export async function updatePrompt(updatedPrompt: Prompt, userToken: string): Promise<Prompt> {
	if (!userToken) return Promise.reject({message: 'There was an error trying to update the prompt, please try again.'})
	const updatedPromptData = await generatePromptData(updatedPrompt)
	return axiosApiRequest(`/prompts/${updatedPrompt.id}`, 'put', userToken, updatedPromptData)
}

export async function storePrompt(prompt: Prompt, userToken: string, tempPromptId: string): Promise<Prompt> {
	if (!userToken) return Promise.reject({message: 'There was an error trying to store the prompt, please try again.'})
	const dataToSend = await generatePromptData(prompt)

	return axiosApiRequest('/prompts', 'post', userToken, {...dataToSend, tempPromptId}, undefined, (response: any) => {
		const prompt: Prompt = response.data
		storeRecentlyUsedPrompt(prompt.id!, userToken)
		return prompt
	})
}

export async function getPrompts(userToken: string, lastEvaluatedKey?: string): Promise<ListPromptsResponse> {
	if (!userToken) return Promise.reject({message: 'There was an error trying to retrieve prompts.'})
	return axiosApiRequest(`/prompts${lastEvaluatedKey ? `?lastEvaluatedKey=${lastEvaluatedKey}` : ''}`, 'get', userToken)
}

export async function getPrompt(promptId: string, userToken: string): Promise<Prompt | undefined> {
	if (!userToken) return Promise.reject({message: 'There was an error trying to retrieve prompts.'})
	return axiosApiRequest(`/prompts/${promptId}`, 'get', userToken)
}

const readAllChunks = async (response: Response, setPromptChats: Dispatch<SetStateAction<PromptChatDetail[]>>, isChatSummary: boolean, modelId: string, sender: 'bot' | 'user', setAmazonQConversationId: Dispatch<SetStateAction<AmazonQResponseSettings | undefined>>, previousMessages: string[], filesMetadata?: FileMetadata[]) => {
	if (!response.body) return ''
	const reader = response.body.getReader()
	const chunks: string[] = []

	let done, value
	while (!done) {
		({value, done} = await reader.read())

		let decodedValue = ''
		if (value) {
			if (modelId === AIModelID.AMAZON_Q) {
				const amazonQResponse = JSON.parse(new TextDecoder().decode(value))
				decodedValue = amazonQResponse.response
				setAmazonQConversationId({conversationId: amazonQResponse.conversationId, systemMessageId: amazonQResponse.systemMessageId})
			} else {
				decodedValue = new TextDecoder().decode(value)
			}
		}

		if (response.status >= 400) {
			setPromptChats((previousValue: PromptChatDetail[]) => {
				const previousChatToUpdate = previousValue.find(previousChat => previousChat.modelId === modelId)
				if (!previousChatToUpdate) return previousValue
				previousChatToUpdate.messages.push({
					text: 'The AI model selected could not return a response. Please try again or use a different model.',
					sender: 'bot',
					regeneratedOutputs: previousMessages || [],
					errorMessage: true
				})
				return Object.assign([], previousValue) as PromptChatDetail[]
			})
			return await Promise.reject({message: JSON.parse(decodedValue).message, response})
		} else {
			setPromptChats((previousValue: PromptChatDetail[]): PromptChatDetail[] => {
				const chatIndexToUpdate = previousValue.findIndex(previousChat => previousChat.modelId === modelId)
				if (chatIndexToUpdate === -1) return previousValue

				const updatedChat: PromptChatDetail = {
					...previousValue[chatIndexToUpdate],
					compilationSummary: {...previousValue[chatIndexToUpdate].compilationSummary},
					messages: [...previousValue[chatIndexToUpdate].messages]
				}

				if (isChatSummary) {
					updatedChat.compilationSummary.summary += decodedValue
					updatedChat.compilationSummary.loading = true
				} else {
					const loadingMessageIndex = updatedChat.messages.findIndex(message => message.loading)
					if (loadingMessageIndex !== -1) {
						const updatedLoadingMessage = {...updatedChat.messages[loadingMessageIndex]}
						updatedLoadingMessage.text += decodedValue
						updatedChat.messages[loadingMessageIndex] = updatedLoadingMessage
					} else {
						updatedChat.messages.push({
							loading: true,
							text: decodedValue,
							sender,
							regeneratedOutputs: previousMessages || [],
							optimized: sender === 'user',
							files: filesMetadata
						})
					}
				}

				const updatedChats = [...previousValue]
				updatedChats[chatIndexToUpdate] = updatedChat
				return Object.assign([], updatedChats) as PromptChatDetail[]
			})
		}
		chunks.push(decodedValue)
		if (done) {
			setPromptChats((previousValue: PromptChatDetail[]) => {
				const previousChatToUpdate = previousValue.find(previousChat => previousChat.modelId === modelId)
				if (!previousChatToUpdate) return previousValue
				const loadingMessage = previousChatToUpdate.messages.find(message => message.loading)

				if (loadingMessage) {
					loadingMessage.text = loadingMessage.text
						.replaceAll('<narusUserInput>', '')
						.replaceAll('</narusUserInput>', '')
						.replaceAll('<narusUserConfiguration>', '')
						.replaceAll('</narusUserConfiguration>', '')
					loadingMessage.loading = false
				}
				if (isChatSummary) {
					previousChatToUpdate.compilationSummary.loading = false
					previousChatToUpdate.compilationSummary.isChatUpdated = false
				}
				return Object.assign([], previousValue) as PromptChatDetail[]
			})
			return chunks.join('')
		}
	}
}


export const executePrompt = async (executePromptParams: PromptExecutionParams): Promise<PromptExecutionResult> => {
	const {
		promptTemplate,
		userToken,
		signal,
		isRegeneratedMessage,
		promptId,
		setPromptChats,
		isChatSummary,
		sender,
		setAmazonQConversationId,
		previousMessages,
		optimizePrompt,
		userMessages,
		fileIds,
		isRagQuery,
		filesMetadata
	} = executePromptParams
	if (!userToken || !executePromptUrl) return Promise.reject({message: 'There was an error trying to run the prompt'})
	const executeBody = {
		prompt: promptTemplate.userPrompt,
		temperature: promptTemplate.temperature,
		model: promptTemplate.modelId,
		frequencyPenalty: promptTemplate.frequencyPenalty,
		audience: promptTemplate.audience,
		format: promptTemplate.format,
		description: promptTemplate.description,
		tone: promptTemplate.tone,
		title: promptTemplate.title,
		language: promptTemplate.language,
		tempPromptId: promptTemplate.tempPromptId,
		conversationId: promptTemplate.conversationId,
		systemMessageId: promptTemplate.systemMessageId,
		chatId: promptTemplate.chatId,
		isRegeneratedMessage,
		optimizePrompt,
		isChatSummary,
		userMessages,
		fileIds,
		isRagQuery,
		...(promptId ? {promptId} : {})
	}

	const response = await fetch(executePromptUrl, {
		...generateConfig(userToken, signal),
		body: JSON.stringify(executeBody),
		method: 'POST',
		retryDelay: 1000,
		retryOn: (attempt: number, error: any, response: Response) => attempt < 3 && (error !== null || response.status === 429 || response.status >= 500)
	})
		.then((response: Response) => readAllChunks(response, setPromptChats, isChatSummary, promptTemplate.modelId, sender, setAmazonQConversationId, previousMessages || [], filesMetadata))
	return {aiOutput: response || '', tokenUsage: 0}
}

export const searchPrompts = async (searchParams: SearchParams, userToken: string): Promise<ListPromptsResponse> => {
	if (!userToken) return Promise.reject({message: 'There was an error trying to search prompts, please try again.'})
	return axiosApiRequest('/search', 'post', userToken, searchParams, undefined, undefined, (error: any) => {
		if (error.response?.status === 404) return Promise.resolve({prompts: [], lastEvaluatedKey: undefined})
		return Promise.reject({message: error.message})
	})
}

export const removePrompt = (promptId: string, userToken: string) => {
	if (!userToken) return Promise.reject({message: 'There was an error trying to delete the prompt.'})
	return axiosApiRequest(`/prompts/${promptId}`, 'delete', userToken)
}

export const markPromptAsFavourite = (promptId: string, userToken: string) => {
	if (!userToken) return Promise.reject({message: 'There was an error trying to mark a prompt as favourite.'})
	return axiosApiRequest(`/prompts/${promptId}/favourite`, 'post', userToken)
}

export const removePromptAsFavourite = (promptId: string, favouriteId: string, userToken: string) => {
	if (!userToken) return Promise.reject({message: 'There was an error trying to unmark a favourite.'})
	return axiosApiRequest(`/prompts/${promptId}/favourite/${favouriteId}`, 'delete', userToken)
}

export async function getFavouritesPromptsByUser(userToken: string): Promise<Prompt[]> {
	if (!userToken) return Promise.reject({message: 'There was an error trying to find your favourites, please try again.'})
	return axiosApiRequest('/prompts/favourites', 'get', userToken, undefined, undefined, undefined, (error: any) => {
		if (error.response?.status === 404) return []
		return Promise.reject({message: error.message})
	})
}

export async function getPromptsByUser(userToken: string): Promise<Prompt[]> {
	if (!userToken) return Promise.reject({message: 'There was an error trying to find your prompts, please try again.'})
	return axiosApiRequest('/prompts/user', 'get', userToken, undefined, undefined, undefined, (error: any) => {
		if (error.response?.status === 404) return []
		return Promise.reject({message: error.message})
	})
}

export async function storeApiKey(apiKey: string | undefined, userToken: string, modelId: string, isEdit: boolean, aiModelName: string): Promise<void> {
	if (!userToken) return Promise.reject({message: 'There was an error trying to store the API key.'})
	return axiosApiRequest(`/apiKey/${modelId}`, isEdit ? 'put' : 'post', userToken, {apiKey, aiModelName})
}

export async function storeRecentlyUsedPrompt(promptId: string, userToken: string): Promise<void> {
	if (!userToken) return Promise.reject({message: 'There were an error trying to save a prompt ‘Recently used’.'})
	return axiosApiRequest(`/prompts/${promptId}/recentlyUsed`, 'post', userToken)
}

export async function listRecentlyUsedPromptByUser(userToken: string): Promise<Prompt[]> {
	if (!userToken) return Promise.reject({message: 'There was an error trying to find your recently used prompts, please try again.'})
	return axiosApiRequest('/prompts/recentlyUsed', 'get', userToken, undefined, undefined, undefined, (error: any) => {
		if (error.response?.status === 404) return []
		return Promise.reject({message: error.message})
	})
}

export async function getLabels(userToken: string): Promise<{ label: string }[]> {
	if (!userToken) return Promise.reject({message: 'There was an error trying to display labels.'})
	return axiosApiRequest('/labels', 'get', userToken, undefined, undefined, undefined, (error: any) => {
		if (error.response?.status === 404) return []
		return Promise.reject({message: error.message})
	})
}

export async function getTeams(userToken: string): Promise<{ team: string }[]> {
	if (!userToken) return Promise.reject({message: 'There was an error trying to display teams.'})
	return axiosApiRequest('/teams', 'get', userToken, undefined, undefined, undefined, (error: any) => {
		if (error.response?.status === 404) return []
		return Promise.reject({message: error.message})
	})
}

export async function getUsageStatistics(userToken: string, dateRangeFilter: DateRange | undefined): Promise<UsageStatistic> {
	if (!userToken) return Promise.reject({message: 'There was an error trying to display statistics.'})
	const dateFilter = dateRangeFilter ? `?from=${dateRangeFilter[0].getTime() / 1000}&to=${dateRangeFilter[1].getTime() / 1000}` : ''
	return axiosApiRequest(`/usageStatistics${dateFilter}`, 'get', userToken)
}

export async function getUsersInfo(userToken: string): Promise<UserInfo[]> {
	if (!userToken) return Promise.reject({message: 'There was an error trying to get users info.'})
	return axiosApiRequest('/userInfo', 'get', userToken)
}

export const getUserInfoByUserId = async (userToken: string, userId: string): Promise<UserInfo> =>
	axiosApiRequest(`/userInfo/${userId}`, 'get', userToken, undefined, undefined, undefined, (error: any) => Promise.reject(error))

export const getUserInfoByUserIdWithRetries = async (userToken: string, userId: string): Promise<UserInfo> =>
	retryAsyncCallback(getUserInfoByUserId, 10, 1000)(userToken, userId)

export async function updateUsersInfo(userToken: string, userInfo: UserInfo): Promise<void> {
	if (!userToken) return Promise.reject({message: 'There was an error trying to update user info.'})
	return axiosApiRequest(`/userInfo/${userInfo.userId}`, 'put', userToken, userInfo)
}

export const updateUserSelectedModels = (userToken: string, selectedAIModels: AIModelID[]): Promise<AIModelID[]> => {
	if (!userToken) return Promise.reject({message: 'There was an error trying to update the user\'s selected models.'})
	return axiosApiRequest('/userInfo/models', 'put', userToken, {selectedAIModels})
}

const generatePromptData = async (prompt: Prompt) => {
	return {
		...prompt,
		creationDate: prompt ? prompt.creationDate : new Date().valueOf(),
		isArchived: prompt.isArchived || false,
	}
}

export async function removeChatHistoryForPromptAndModel(promptId: string, modelId: string, userToken: string) {
	if (!userToken || !promptId || !modelId) return Promise.reject({message: 'There was an error removing chat history'})
	return axiosApiRequest(`/chatHistory/${promptId}/${modelId}`, 'delete', userToken)
}

export const removeAiModel = (modelId: string, userToken: string) => {
	if (!userToken) return Promise.reject({message: 'There was an error trying to delete the AI model.'})
	return axiosApiRequest(`/aiModels/${modelId}`, 'delete', userToken)
}

export const updateAiModel = (modelId: string, userToken: string, aiModelToUpdate: AIModel) => {
	if (!userToken) return Promise.reject({message: 'There was an error trying to update the AI model.'})
	return axiosApiRequest(`/aiModels/${modelId}`, 'put', userToken, aiModelToUpdate)
}

export const getAiModels = (userToken: string) => {
	if (!userToken) return Promise.reject({message: 'There was an error trying to retrieve the AI models.'})
	return axiosApiRequest('/aiModels', 'get', userToken)
}

export const storeAiModel = (modelId: string, apiKey: string, userToken: string, name: string) => {
	if (!userToken) return Promise.reject({message: 'There was an error trying to store the AI model.'})
	return axiosApiRequest(`/aiModels/${modelId}`, 'post', userToken, {apiKey, name})
}

export const storeChat = (userToken: string, chat: Chat, tempPromptId: string): Promise<Chat> => {
	if (!userToken) return Promise.reject({message: 'There was an error trying to store the chat.'})
	return axiosApiRequest('/chats', 'post', userToken, {...chat, tempPromptId})
}

export const updateChat = (userToken: string, chatToUpdate: Chat): Promise<Chat> => {
	if (!userToken) return Promise.reject({message: 'There was an error trying to update the chat.'})
	return axiosApiRequest(`/chats/${chatToUpdate.chatId}`, 'put', userToken, chatToUpdate)
}

export const getChatsByUser = (userToken: string): Promise<Chat[]> => {
	if (!userToken) return Promise.reject({message: 'There was an error trying to get chats.'})
	return axiosApiRequest(`/chats`, 'get', userToken)
}

export const getAuditingLogs = (userToken: string, dateRangeFilter: DateRange | undefined): Promise<UserAuditLog[]> => {
	if (!userToken) return Promise.reject({message: 'There was an error trying to retrieve the auditing logs.'})
	const dateFilter = dateRangeFilter ? `?from=${dateRangeFilter[0].getTime() / 1000}&to=${dateRangeFilter[1].getTime() / 1000}` : ''
	return axiosApiRequest(`/auditingLogs${dateFilter}`, 'get', userToken)
}

export const markAuditingLogAsViewed = (userToken: string, auditingLog: UserAuditLog): Promise<void> => userToken
	? axiosApiRequest('/auditingLogs', 'put', userToken, {auditLogIds: auditingLog.auditLogIds, action: 'view'})
	: Promise.reject({message: 'There was an error trying to update auditing log.'})

export const getUserOrganization = (userToken: string): Promise<UserOrganization> =>
	axiosApiRequest('/userOrganization', 'get', userToken, undefined, undefined, undefined, (error: any) => Promise.reject(error))

export const getUserOrganizationWithRetries = (userToken: string): Promise<UserOrganization> =>
	retryAsyncCallback(getUserOrganization, 5, 1000)(userToken)

export const createOrganization = (userToken: string, name: string, tableSuffix: string, allowedDomains: string[], awsMarketplaceCustomerData: AwsMarketplaceCustomerData): Promise<UserOrganization> =>
	axiosApiRequest('/createOrganization', 'post', userToken, {
		name,
		tableSuffix,
		allowedDomains, ...awsMarketplaceCustomerData
	}, undefined, undefined, (error: any) => Promise.reject(error))

export const getOrganizationByUser = (userToken: string): Promise<Organization> => {
	if (!userToken) return Promise.reject({message: 'There was an error trying to retrieve the auditing logs.'})
	return axiosApiRequest('/organization', 'get', userToken)
}

export const updateOrganization = (userToken: string, name: string, allowedDomains: string[]): Promise<Organization> => {
	if (!userToken) return Promise.reject({message: 'There was an error trying to update the chat.'})
	return axiosApiRequest('/organization', 'put', userToken, {name, allowedDomains})
}

export const createOrganizationWithRetries = async (userToken: string, name: string, tableSuffix: string, allowedDomains: string[], awsMarketplaceCustomerData: AwsMarketplaceCustomerData): Promise<UserOrganization> => {
	const creationResponse = await createOrganization(userToken, name, tableSuffix, allowedDomains, awsMarketplaceCustomerData).catch(error => error as AxiosError)

	// If creation succeed in time, return its result, which is a userOrganization object
	if ('organizationId' in creationResponse) {
		return creationResponse
	}

	// Reject when there is a "createOrganization" error different than "service timeout"
	if ('response' in creationResponse && creationResponse.response?.status && creationResponse.response.status !== 503) {
		return Promise.reject(creationResponse)
	}

	// get user organization with retries
	const maxTries = 20
	let tries = 0

	while (tries < maxTries) {
		tries++
		const userOrganizationResponse = await getUserOrganization(userToken).catch(error => error as AxiosError)
		// Reject when there is a "userOrganization" error different than "not found"
		if ('response' in userOrganizationResponse && userOrganizationResponse.response?.status !== 404) {
			return Promise.reject(userOrganizationResponse)
		}

		if ('organizationId' in userOrganizationResponse) {
			return userOrganizationResponse
		}
		await waitTime(5000)
	}

	return Promise.reject('Unexpected error')
}

export const createAdminEmails = (userToken: string, emails: string[]) =>
	axiosApiRequest('/admins', 'post', userToken, {emails}, undefined, undefined, (error: any) => Promise.reject(error))

export const updateAdminEmails = (userToken: string, emails: string[]): Promise<AdminEmail[]> =>
	axiosApiRequest('/admins', 'put', userToken, {emails})

export const getAdminEmails = (userToken: string): Promise<AdminEmail[]> =>
	axiosApiRequest('/admins', 'get', userToken)

export const getTopicWarnings = (userToken: string): Promise<TopicWarning[]> =>
	axiosApiRequest('/topicWarnings', 'get', userToken)

export const saveTopicWarning = (userToken: string, topic: string, warningLevel: string): Promise<TopicWarning> =>
	axiosApiRequest('/topicWarnings', 'post', userToken, {topic, level: warningLevel})

export const updateTopicWarning = (userToken: string, topicWarning: TopicWarning): Promise<TopicWarning> =>
	axiosApiRequest(`/topicWarnings/${topicWarning.hashKey}`, 'put', userToken, topicWarning)

export const deleteTopicWarning = (userToken: string, topicWarningId: string): Promise<TopicWarning[]> =>
	axiosApiRequest(`/topicWarnings/${topicWarningId}`, 'delete', userToken)

export const getWordWarnings = (userToken: string): Promise<WordWarning[]> =>
	axiosApiRequest('/wordWarnings', 'get', userToken)

export const saveWordWarning = (userToken: string, word: string, warningLevel: string): Promise<WordWarning> =>
	axiosApiRequest('/wordWarnings', 'post', userToken, {word, level: warningLevel})

export const updateWordWarning = (userToken: string, wordWarning: WordWarning): Promise<WordWarning> =>
	axiosApiRequest(`/wordWarnings/${wordWarning.hashKey}`, 'put', userToken, wordWarning)

export const deleteWordWarning = (userToken: string, wordWarningId: string): Promise<WordWarning[]> =>
	axiosApiRequest(`/wordWarnings/${wordWarningId}`, 'delete', userToken)

export const getFormatWarnings = (userToken: string): Promise<FormatWarning[]> =>
	axiosApiRequest('/formatWarnings', 'get', userToken)

export const updateFormatWarning = (userToken: string, formatWarning: FormatWarning): Promise<FormatWarning> =>
	axiosApiRequest(`/formatWarnings/${formatWarning.hashKey}`, 'put', userToken, formatWarning)

export const getChatMessagesByChatId = (userToken: string, chatId: string): Promise<ChatMessageStored[]> => {
	if (!userToken) return Promise.reject({message: 'There was an error trying to retrieve the chat messages.'})
	return axiosApiRequest(`/chats/${chatId}`, 'get', userToken)
}

export const removeChat = (userToken: string, chatId: string): Promise<ChatMessageStored[]> => {
	if (!userToken) return Promise.reject({message: 'There was an error trying to remove chat.'})
	return axiosApiRequest(`/chats/${chatId}`, 'delete', userToken)
}

export const createGroup = (userToken: string, groupName: string, userIdsToAdd: string[]): Promise<[number, Group]> => {
	if (!userToken) return Promise.reject({message: 'There was an error trying to create group.'})
	return axiosApiRequest('/groups', 'post', userToken, {groupName, userIdsToAdd}, undefined, (response: any) => [response.status, response.data])
}

export const getGroupMembersByGroupId = (userToken: string, groupId: string): Promise<GroupMember[]> => {
	if (!userToken) return Promise.reject({message: 'There was an error trying to retrieve the group members.'})
	return axiosApiRequest(`/group/${groupId}/members`, 'get', userToken, undefined, undefined, undefined, (error: any) => {
		if (error.response?.status === 404) return []
		return Promise.reject({message: error.message})
	})
}

export const getGroups = (userToken: string): Promise<Group[]> => {
	if (!userToken) return Promise.reject({message: 'There was an error trying to retrieve groups.'})
	return axiosApiRequest('/groups', 'get', userToken, undefined, undefined, undefined, (error: any) => {
		if (error.response?.status === 404) return []
		return Promise.reject({message: error.message})
	})
}

export const removeGroup = (userToken: string, groupId: string): Promise<void> => {
	if (!userToken) return Promise.reject({message: 'There was an error trying to remove group.'})
	return axiosApiRequest(`/groups/${groupId}`, 'delete', userToken)
}

export const getSubscriptions = (userToken: string): Promise<Subscription[]> => {
	if (!userToken) return Promise.reject({message: 'There was an error trying to retrieve subscriptions.'})
	return axiosApiRequest('/subscriptions', 'get', userToken, undefined, undefined, undefined, (error: any) => {
		if (error.response?.status === 404 || error.response?.status === 503) return []
		return Promise.reject({message: error.message})
	})
}

export const updateGroup = (userToken: string, groupId: string, groupName: string, disabledAIModels?: AIModelID[]): Promise<Group> => {
	if (!userToken) return Promise.reject({message: 'There was an error trying to update group.'})
	return axiosApiRequest(`/groups/${groupId}`, 'put', userToken, {groupName, disabledAIModels})
}

export const getGroupByUserId = (userToken: string): Promise<Group | undefined> => {
	if (!userToken) return Promise.reject({message: 'There was an error trying to get the user group.'})
	return axiosApiRequest('/userGroup', 'get', userToken, undefined, undefined, undefined, (error: any) => {
		if (error.response?.status === 404) return undefined
		return Promise.reject({message: error.message})
	})
}

export const updateGroupMembers = (userToken: string, groupId: string, userIds: string[]): Promise<GroupMember[]> => {
	if (!userToken) return Promise.reject({message: 'There was an error trying to update group members.'})
	return axiosApiRequest(`/group/${groupId}/members`, 'put', userToken, {groupId, userIds})
}

export const createBudget = (userToken: string, budget: Partial<Budget>): Promise<Budget> => {
	if (!userToken) return Promise.reject({message: 'There was an error trying to create a budget.'})
	const {amount, periodicity, startDate, endDate, groupId} = budget
	return axiosApiRequest('/budget', 'post', userToken, {amount, periodicity, startDate, endDate, groupId})
}

export const updateBudget = (userToken: string, budgetId: string, amount: number): Promise<Budget> => {
	if (!userToken) return Promise.reject({message: 'There was an error trying to update a budget.'})
	return axiosApiRequest(`/budget/${budgetId}`, 'put', userToken, {amount})
}

export const closeBudget = (userToken: string, budgetId: string): Promise<Budget> => {
	if (!userToken) return Promise.reject({message: 'There was an error trying to close a budget.'})
	return axiosApiRequest(`/budget/${budgetId}`, 'put', userToken, {periodEndDate: new Date().getTime()})
}

export const deleteBudget = (userToken: string, budgetId: string): Promise<void> => {
	if (!userToken) return Promise.reject({message: 'There was an error trying to delete a budget.'})
	return axiosApiRequest(`/budget/${budgetId}`, 'delete', userToken)
}

export const getOverallBudget = (userToken: string): Promise<Budget | undefined> => {
	if (!userToken) return Promise.reject({message: 'There was an error trying to get overall budget.'})
	return axiosApiRequest('/budget', 'get', userToken, undefined, undefined, undefined, (error: any) => {
		if (error.response?.status === 404) return undefined
		return Promise.reject({message: error.message})
	})
}

export const getUserHasBudgetRemaining = (userToken: string): Promise<boolean> => {
	if (!userToken) return Promise.reject({message: 'There was an error trying to get if the user has budget remaining.'})
	return axiosApiRequest('/budgetRemaining', 'get', userToken, undefined, undefined, (response: any) => {
		return response?.data?.budgetRemaining ?? false
	})
}

export const getClosedBudgets = (userToken: string): Promise<ClosedBudget[]> => {
	if (!userToken) return Promise.reject({message: 'There was an error trying to get Organization Closed Budgets'})
	return axiosApiRequest('/closedBudgets', 'get', userToken, undefined, undefined, undefined, (error: any) => {
		if (error.response?.status === 404) return []
		return Promise.reject({message: error.message})
	})
}

export const sendMarketingCommsEvent = (userToken: string, eventData: AnalyticsEvent): Promise<void> => {
	if (!userToken) return Promise.reject({message: 'There was an error trying to send the event.'})
	return axiosApiRequest('/marketingEvent', 'post', userToken, eventData)
}

export const getUploadFileURL = (userToken: string, filename: string, conversationId: string | undefined, messageId: string | undefined): Promise<{
	Key: string,
	uploadURL: string
}> => {
	if (!userToken) return Promise.reject({message: 'There was an error trying to get the upload file URL.'})
	const queryParams = conversationId && messageId ? `?conversationId=${conversationId}&messageId=${messageId}` : ''
	return axiosApiRequest(`/uploadSignedURL/${filename}${queryParams}`, 'get', userToken)
}

export const putFile = async (url: string, file: File): Promise<Response> => {
	let headers = {'content-type': 'multipart/form-data'}
	if (file?.type) {
		headers['Content-Type'] = file!.type
	}
	return await fetch(url, {body: file, headers, method: 'PUT'})
}

export const deleteFile = async (userToken: string, fileKey: string): Promise<void> => {
	if (!userToken) return Promise.reject({message: 'There was an error trying to delete the file.'})

	return axiosApiRequest(`/file/${fileKey}`, 'delete', userToken)
}

export const checkFilesLoadStatus = (userToken: string, fileIds: FileId[]): Promise<{ areFilesLoaded: boolean }> => {
	if (!userToken) return Promise.reject({message: 'There was an error trying to get the files status.'})
	return axiosApiRequest('/filesLoadStatus', 'post', userToken, {fileIds})
}

export const createSubscription = (userToken: string, priceId: string, seats: number): Promise<SubscriptionCreationResult> => {
	if (!userToken) return Promise.reject({message: 'There was an error trying to create the subscription.'})
	return axiosApiRequest('/subscription', 'post', userToken, {priceId, seats})
}

export const cancelSubscription = (userToken: string, subscriptionId: string): Promise<void> => {
	if (!userToken) return Promise.reject({message: 'There was an error trying to cancel the subscription.'})
	return axiosApiRequest(`/subscription/${subscriptionId}`, 'delete', userToken)
}

export const createPaymentIntent = (userToken: string, amount: number): Promise<{ clientSecret: string }> => {
	if (!userToken) return Promise.reject({message: 'There was an error trying to create the payment needed data.'})
	return axiosApiRequest('/paymentIntent', 'post', userToken, {amount})
}

export const getPaymentTaxes = (userToken: string, amount: number, seats: number, planId: string, address: CustomerAddress): Promise<TaxesInfo> => {
	if (!userToken) return Promise.reject({message: 'There was an error trying to get the taxes.'})
	return axiosApiRequest(`/taxes`, 'post', userToken, {amount, seats, planId, ...address})
}

export const updateSubscription = (userToken: string, changePlanActionData: ChangePlan): Promise<void> => {
	if (!userToken) return Promise.reject({message: 'There was an error trying to update the subscription.'})
	return axiosApiRequest('/subscription', 'put', userToken, changePlanActionData)
}

export const deleteUser = (userToken: string, userId: string, email: string): Promise<void> => {
	if (!userToken) return Promise.reject({message: 'There was an error trying to delete the user.'})
	return axiosApiRequest('/clerkUser', 'delete', userToken, {userId, email})
}

const axiosApiRequest = (
	URL: string,
	method: 'get' | 'post' | 'put' | 'delete',
	userToken: string,
	data: any = {},
	signal?: AbortSignal,
	customCallback?: any,
	customErrorCallback?: any
) => {
	let axiosParams: [string, any, any?] = [URL, generateConfig(userToken, signal)]

	switch (method) {
		case 'get':
			axiosParams[1] = {...generateConfig(userToken, signal), params: data}
			break
		case 'delete':
			axiosParams[1] = {...generateConfig(userToken, signal), data}
			break
		default:
			axiosParams[1] = data
			axiosParams[2] = generateConfig(userToken, signal)
			break
	}

	return client[method](...axiosParams)
		.then((response: any) => customCallback ? customCallback(response) : response.data)
		.catch((error: any) => customErrorCallback
			? customErrorCallback(error)
			: Promise.reject({message: error.response?.data?.message ?? error.message, cause: error.response?.status}))
}
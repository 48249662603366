import {AppGridWrapper} from '../../appGridWrapper/AppGridWrapper'
import {
    Button, Checkbox, Chip, Divider,
    FormControlLabel,
    Grid,
    Paper,
    Stack,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from '@mui/material'
import Box from '@mui/material/Box'
import SavingsIcon from '@mui/icons-material/SavingsOutlined'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'
import {getPeriodByPriceId, getPlanByPriceId, getPlanPrice, toRoundedSubunit} from '../../../utils/licenseUtils'
import CheckIcon from '@mui/icons-material/Check'
import {PRICES_INFO_MAP} from '../../../constants/StripeConstants'
import {formatAmount} from '../../../utils/currencyUtils'
import {usePaymentContext} from '../../../context/PaymentContext'
import {TrackActionEvent} from '../../../service/SegmentService'
import {useNavigate} from 'react-router-dom'
import {UserButton, useUser} from '@clerk/clerk-react'
import {PaidPlan, Period, Plan} from '../../../types/Stripe'
import {formatDateStringToLocaleDate} from '../../../helpers/DateHelpers'
import {useStripeSubscriptionsContext} from '../../../context/StripeSubscriptionsContext'
import './PricingContent.scss'
import {NarusLogo} from '../../icons/NarusLogo'
import { SeatsCountInput } from './SeatsCountInput'

interface PricingContentProps {
    isChangePlan: boolean
}

export const PricingContent = ({isChangePlan}: PricingContentProps) => {

    const navigate = useNavigate()
    const {user} = useUser()

    const {
        seats,
        setPlanId,
        selectedPlan,
        setSelectedPlan,
        periodSelected,
        setPeriodSelected,
        setAmount,
        setOpenChangePlanDialog,
        setOpenChangePlanConfirmationDialog
    } = usePaymentContext()
    const {currentSubscription} = useStripeSubscriptionsContext()

    const currentPlan = currentSubscription ? getPlanByPriceId(currentSubscription.plan.id) : undefined
    const currentPeriod = currentSubscription ? getPeriodByPriceId(currentSubscription.plan.id) : undefined
    const licenseExpirationDate = currentSubscription ? formatDateStringToLocaleDate(currentSubscription.current_period_end * 1000) : undefined
    const isCurrentPlanAndPeriod = currentSubscription && (currentPlan === selectedPlan && currentPeriod === periodSelected)

    const getCurrentPlanChip = (plan: Plan, period: Period) =>
        currentSubscription && currentPlan === plan && currentPeriod === period && isChangePlan ?
            <Chip className='currentPlanChip' label='Current plan'/> : <></>

    const selectedPlanHandler = (plan: PaidPlan) => {
        setSelectedPlan(plan)
        TrackActionEvent('Change Plan', user?.externalId ?? user?.id, {selected_plan: plan})
    }

    const actionClickHandler = () => {
        if (!selectedPlan) return
        setPlanId(PRICES_INFO_MAP[selectedPlan][periodSelected].priceId)
        setAmount(toRoundedSubunit(seats * getPlanPrice(selectedPlan, periodSelected, seats)))
        if (currentSubscription) {
            setOpenChangePlanDialog(false)
            setOpenChangePlanConfirmationDialog(true)
        } else {
            TrackActionEvent('Pricing table', user?.externalId ?? user?.id, {action: 'go_payment'})
            navigate('/payment')
        }
    }

    return <AppGridWrapper>
        <Grid container className='planContentContainer'>
            {currentSubscription && isChangePlan ? <></> : <Grid xs={12} className='headerWrapper' item>
                <Box className='headerWelcome'>
                    <NarusLogo/>
                </Box>
                <UserButton showName={false}/>
            </Grid>}

            <Grid item xs={12} className='planContentDescription'>
                <Typography variant='h6'>Compare Narus plans and choose the right solution for your business:</Typography>
            </Grid>

            <Grid item xs={12} className='planContentPeriodContainer'>
                <ToggleButtonGroup size='large' exclusive className='planContentPeriodSelector' value={periodSelected}>
                    <ToggleButton value='monthly' onClick={() => setPeriodSelected('monthly')}>
                        <Typography>Monthly</Typography>
                    </ToggleButton>
                    <ToggleButton value='annual' onClick={() => setPeriodSelected('annual')}>
                        <Stack direction='column' spacing={0.5} alignItems='center'>
                            <Typography>Annually</Typography>
                            <Box display='flex' alignItems='center' sx={{gap: 0.5}}>
                                <SavingsIcon sx={{color: '#7755CC'}}/>
                                <Typography variant='body1' color='#7755CC'>Save up to 17%</Typography>
                            </Box>
                        </Stack>
                    </ToggleButton>
                </ToggleButtonGroup>
            </Grid>
            <Grid item xs={8.75} className='planContentContainerRow'>
                <Box className='planContentSeats'>
                    <SeatsCountInput trackingOrigin='Pricing table' isChangePlan={isChangePlan}/>
                    <Typography>Price per seat varies according to users added.</Typography>
                </Box>
                <Box className='plansContentRow'>
                    <Paper className={`planContainer ${selectedPlan === 'growth' ? 'selected' : ''} ${isChangePlan ? 'changePlan' : ''}`}>
                        {getCurrentPlanChip('growth', periodSelected)}
                        <Box className='titleContainer'>
                            <FormControlLabel
                                control={<Checkbox className='planCheckbox' icon={<RadioButtonUncheckedIcon/>}
                                                   onClick={() => selectedPlanHandler('growth')}
                                                   checkedIcon={<RadioButtonCheckedIcon className='radioButtonCheckedIcon'/>}
                                                   disabled={currentSubscription && currentPlan === 'growth' && currentPeriod === periodSelected}
                                                   checked={selectedPlan === 'growth'}/>}
                                className='planTitle' label='Growth'/>
                            <Typography
                                className='price'>${getPlanPrice('growth', periodSelected, seats)} user/month</Typography>
                        </Box>

                        <Box className='benefit'>
                            <CheckIcon/>
                            <Typography>Llama-3 and Mistral models</Typography>
                        </Box>
                        <Box className='benefit'>
                            <CheckIcon/>
                            <Typography>Connect your own APIs</Typography>
                        </Box>
                        <Box className='benefit'>
                            <CheckIcon/>
                            <Typography>AI Expense tracking</Typography>
                        </Box>
                        <Box className='benefit'>
                            <CheckIcon/>
                            <Typography>User activity dashboard</Typography>
                        </Box>
                        <Box className='benefit'>
                            <CheckIcon/>
                            <Typography>Multi-model chat portal</Typography>
                        </Box>
                        <Box className='benefit'>
                            <CheckIcon/>
                            <Typography>Prompt optimization</Typography>
                        </Box>
                        <Box className='benefit'>
                            <CheckIcon/>
                            <Typography>3-month audit history</Typography>
                        </Box>
                    </Paper>
                    <Paper className={`planContainer ${selectedPlan === 'scale' ? 'selected' : ''} ${isChangePlan ? 'changePlan' : ''}`}>
                        {getCurrentPlanChip('scale', periodSelected)}
                        <Box className='titleContainer'>
                            <FormControlLabel
                                control={<Checkbox className='planCheckbox' icon={<RadioButtonUncheckedIcon/>}
                                                   onClick={() => selectedPlanHandler('scale')}
                                                   checkedIcon={<RadioButtonCheckedIcon className='radioButtonCheckedIcon'/>}
                                                   disabled={currentSubscription && currentPlan === 'scale' && currentPeriod === periodSelected}
                                                   checked={selectedPlan === 'scale'}/>} label='Scale'
                                className='planTitle'/>
                        </Box>
                        <Typography
                            className='price'>${getPlanPrice('scale', periodSelected, seats)} user/month</Typography>

                        <Box className='benefit'>
                            <CheckIcon/>
                            <Typography>Everything in Growth plan</Typography>
                        </Box>
                        <Box className='benefit'>
                            <CheckIcon/>
                            <Typography>Unlimited API connections</Typography>
                        </Box>
                        <Box className='benefit'>
                            <CheckIcon/>
                            <Typography>AI model access control</Typography>
                        </Box>
                        <Box className='benefit'>
                            <CheckIcon/>
                            <Typography>Team management</Typography>
                        </Box>
                        <Box className='benefit'>
                            <CheckIcon/>
                            <Typography>Budget control</Typography>
                        </Box>
                        <Box className='benefit'>
                            <CheckIcon/>
                            <Typography>Safeguard configuration</Typography>
                        </Box>
                        <Box className='benefit'>
                            <CheckIcon/>
                            <Typography>Security alerts</Typography>
                        </Box>
                        <Box className='benefit'>
                            <CheckIcon/>
                            <Typography>Risk reporting</Typography>
                        </Box>
                        <Box className='benefit'>
                            <CheckIcon/>
                            <Typography>6-month audit history</Typography>
                        </Box>

                    </Paper>
                    <Paper className={`planContainer ${selectedPlan === 'ultimate' ? 'selected' : ''} ${isChangePlan ? 'changePlan' : ''}`}>
                        {getCurrentPlanChip('ultimate', periodSelected)}
                        <Box className='titleContainer'>
                            <FormControlLabel
                                control={<Checkbox className='planCheckbox' icon={<RadioButtonUncheckedIcon/>}
                                                   onClick={() => selectedPlanHandler('ultimate')}
                                                   checkedIcon={<RadioButtonCheckedIcon className='radioButtonCheckedIcon'/>}
                                                   disabled={currentSubscription && currentPlan === 'ultimate' && currentPeriod === periodSelected}
                                                   checked={selectedPlan === 'ultimate'}/>} label='Ultimate'
                                className='planTitle'/>
                        </Box>
                        <Typography className='price'>Contact us</Typography>

                        <Box className='benefit'>
                            <CheckIcon/>
                            <Typography>Everything in Scale plan</Typography>
                        </Box>
                        <Box className='benefit'>
                            <CheckIcon/>
                            <Typography>SAML / SSO</Typography>
                        </Box>
                        <Box className='benefit'>
                            <CheckIcon/>
                            <Typography>Connect knowledge source</Typography>
                        </Box>
                        <Box className='benefit'>
                            <CheckIcon/>
                            <Typography>Unlimited audit history</Typography>
                        </Box>
                    </Paper>
                </Box>
            </Grid>
            <Divider orientation='vertical' sx={{p: 1}} flexItem/>
            <Grid item xs={3} className='planContentContainerRow summaryContainer'>
                <Box sx={{px: 2, pt: 2, height: '100%'}}>
                    <Stack spacing={2} sx={{height: '100%'}}>
                        <Typography className='title summaryTitle'>Summary</Typography>
                        <Stack>
                            <Box display='flex' justifyContent='space-between'>
                                <Typography variant='body1'
                                            className='summaryInfo currentPlanText'>{isChangePlan && currentSubscription ? (isCurrentPlanAndPeriod ? 'Current plan' : 'Change plan to') : 'Selected plan'}</Typography>
                                {selectedPlan && <Typography variant='body1'
                                                             className='summaryInfo currentSubscriptionInfo'>{PRICES_INFO_MAP[selectedPlan].name} {periodSelected}</Typography>}
                            </Box>
                            <Box display='flex' justifyContent='flex-end'>
                                <Typography variant='body1'
                                            className='summaryInfo currentSubscriptionInfo'>{seats} {seats === 1 ? 'seat' : 'seats'}</Typography>
                            </Box>
                            <Box display='flex' justifyContent='flex-end'>
                                <Typography variant='body1'
                                            className='summaryInfo currentSubscriptionInfo'>${getPlanPrice(selectedPlan, periodSelected, 1)} per
                                    seat</Typography>
                            </Box>
                        </Stack>
                        <Box>
                            <Divider sx={{my: 1}}/>
                            <Box display='flex' justifyContent='space-between' alignItems='center'>
                                <Typography variant='body1'
                                            className='summaryInfo periodCostInfo'>{`${periodSelected === 'annual' ? 'Annually' : 'Monthly'} cost`}</Typography>
                                <Typography variant='body1'
                                            className='summaryInfo periodCostInfo'>{formatAmount((seats ?? 0) * getPlanPrice(selectedPlan, periodSelected, seats) * (periodSelected === 'annual' ? 12 : 1))}</Typography>
                            </Box>
                            {!isCurrentPlanAndPeriod && currentSubscription && isChangePlan ? <>
                                <Box display='flex' justifyContent='flex-end' sx={{mt: 1}}>
                                    <Typography variant='body2'
                                                className='summaryInfo nextChargeInfo'>Next charge on</Typography>
                                </Box>
                                <Box display='flex' justifyContent='flex-end'>
                                    <Typography variant='body2'
                                                className='summaryInfo nextChargeInfo'>{licenseExpirationDate}</Typography>
                                </Box>
                            </> : <></>}
                        </Box>
                        <Box className='selectPlanButtonContainer'>
                            {selectedPlan === 'ultimate' ?
                                <Typography className='selectPlanButton ultimate'>Contact us in hello@narus.com</Typography>
                                : <Button variant='contained' className='selectPlanButton'
                                          disabled={!seats || !selectedPlan || (!!seats && seats > 250) || (isCurrentPlanAndPeriod && isChangePlan)}
                                          onClick={actionClickHandler}>{currentSubscription && isChangePlan ? 'Change plan' : 'Payment'}</Button>}
                        </Box>
                    </Stack>
                </Box>
            </Grid>
        </Grid>
    </AppGridWrapper>
}
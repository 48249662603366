import {FC} from 'react'
import {FileMetadata} from '../../types/File'
import {Box, Typography} from '@mui/material'
import DocumentIcon from '@mui/icons-material/DescriptionOutlined'
import './MessageFiles.scss'

type Props = {
    files?: FileMetadata[]
}

export const MessageFiles: FC<Props> = ({
    files
}) => files?.length 
    ? <Box className='messageFilesContainer'>
        {files.map(({name, timestamp}, index) => (
            <Box className='messageFilesItem' key={`file-${index}`}>
                <DocumentIcon className='itemIcon'/>
                <Box className='itemDescription'>
                    <Typography className='itemName'>{name}</Typography>
                    <Typography className='itemDate'>{timestamp}</Typography>
                </Box>
            </Box>
        ))}
    </Box> : <></>

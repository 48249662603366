import { FC, useEffect } from 'react'
import { AccountCreationAside } from './AccountCreationAside'
import { Box } from '@mui/material'
import { AccountCreationForm } from './AccountCreationForm'
import { StepIndex, StepIndexes } from '../../types/AccountCreation'
import { useAccountCreationContext } from '../../context/AccountCreationContext'
import { AccountCreationError } from './AccountCreationError'
import { AccountCreationProgress } from './AccountCreationPogress'
import { useLocation, useNavigate } from 'react-router-dom'
import { ACCOUNT_CREATION_CONFIG } from '../../constants/AccountCreationConstants'
import { getFirstIncompleteIndex } from '../../utils/accountCreationHelpers'
import { AccountCreationCompleted } from './AccountCreationCompleted'
import { AiModelsConfig, AiModelsContextProvider } from '../../context/AIModelsContext'
import { useUserOrganizationContext } from '../../context/UserOrganizationContext'
import { isUserOrganizationFilled } from '../../utils/userOrganizationUtils'
import './AccountCreationStep.scss'
import {FeedbackMessage} from "../feedbackMessage/FeedbackMessage";

type AccountCreationStepProps = {
    index: StepIndex
}

const aiModelsCustomConfig: AiModelsConfig = {
    retrieveFeedbackVisible: false
}

export const AccountCreationStep: FC<AccountCreationStepProps> = ({ index }) => {
    const { pathname } = useLocation()
    const navigate = useNavigate()
    const { stepsState } = useAccountCreationContext()
    const { userOrganization } = useUserOrganizationContext()

    const showProgressPage = stepsState[index] === 'loading' && index === 0
    const showErrorPage = stepsState[index] === 'error'
    const showCompletedPage = stepsState[index] === 'completed' && index === 2
    const firstStepCompleted = stepsState[0] === 'completed'
    const shouldBeRedirectedToHomePage = isUserOrganizationFilled(userOrganization) && StepIndexes.every(stepIndex => stepsState[stepIndex] !== 'completed')

    // Dont let user to hardcode step locations not reached 
    // Automate navigation to last incomplete page (if there is any)
    useEffect(() => {
        const firstIncompleteIndex = getFirstIncompleteIndex(stepsState)
        
        if (firstIncompleteIndex !== undefined && firstIncompleteIndex !== index) {
            const { page } = ACCOUNT_CREATION_CONFIG[firstIncompleteIndex]

            navigate(page)
        }
    },[index, pathname, navigate, stepsState])

    // When there is userOrganization, but no step  was completed, it means that
    // This creation process was already done before, so transport the user back to main page
    useEffect(() => {
        if (shouldBeRedirectedToHomePage) {
            navigate('/')
        }
    }, [shouldBeRedirectedToHomePage, navigate])

    return <>
        <Box className='AccountCreationWrapper'>
            {
                shouldBeRedirectedToHomePage ? <></> :
                    showCompletedPage ? <AccountCreationCompleted/> :
                        <Box className='AccountCreationContainer'>
                            <AccountCreationAside index={index}/>
                            {
                                showErrorPage ? <AccountCreationError index={index}/> :
                                    showProgressPage ? <AccountCreationProgress/> :
                                        firstStepCompleted ?
                                            <AiModelsContextProvider config={aiModelsCustomConfig}><AccountCreationForm
                                                index={index}/></AiModelsContextProvider> :
                                            <AccountCreationForm index={index}/>
                            }
                        </Box>
            }
        </Box>
        <FeedbackMessage/>
    </>
}
import {
    GROWTH_MONTHLY_PRICE_ID,
    GROWTH_YEARLY_PRICE_ID,
    PRICES_INFO_MAP,
    SCALE_MONTHLY_PRICE_ID,
    TRIAL_PRICE_ID,
    ULTIMATE_MONTHLY_PRICE_ID
} from '../constants/StripeConstants'
import {PaidPlan, Period, Plan} from '../types/Stripe'
import {PLAN_HIERARCHY} from '../constants/LicenseConstants'
import {formatAmount} from './currencyUtils'

export const getPlanPrice = (plan: Plan | undefined, period: Period, seats: number): number => {
    if (!plan || plan === 'trial') return 0

    const prices = PRICES_INFO_MAP[plan][period].priceBySeats
    const bucketSeats = Object.keys(prices).find(bucketSeats => seats <= parseInt(bucketSeats))
    return bucketSeats ? prices[bucketSeats] : 0
}

export const getPlanByPriceId = (priceId: string): Plan => {
    switch (priceId) {
        case TRIAL_PRICE_ID:
            return 'trial'
        case GROWTH_YEARLY_PRICE_ID:
        case GROWTH_MONTHLY_PRICE_ID:
            return 'growth'
        case ULTIMATE_MONTHLY_PRICE_ID:
            return 'ultimate'
        default:
            return 'scale'
    }
}

export const getPeriodByPriceId = (priceId: string): Period => {
    if ([GROWTH_MONTHLY_PRICE_ID, SCALE_MONTHLY_PRICE_ID, ULTIMATE_MONTHLY_PRICE_ID].includes(priceId)) return 'monthly'
    return 'annual'
}

export const isUpgradedPlan = (currentPlan: Plan, updatedPlan: Plan, oldSeats?: number, newSeats?: number): boolean =>
    PLAN_HIERARCHY.indexOf(updatedPlan) > PLAN_HIERARCHY.indexOf(currentPlan) || (updatedPlan === currentPlan && newSeats !== undefined && oldSeats !== undefined && newSeats > oldSeats)


// Stripe expects the amount to be in the currency's subunit, which for most currencies means cents
export const toRoundedSubunit = (amount: number): number => Math.round(amount * 100)

export const getPaymentPrice = (seats: number, plan: Plan, period: Period) =>
    formatAmount(seats * getPlanPrice(plan, period, seats) * (period === 'annual' ? 12 : 1))

export const getPlanPriceId = (plan: Plan | PaidPlan, period?: Period): string => {
    if (plan === 'trial' || !period) return TRIAL_PRICE_ID!
    return PRICES_INFO_MAP[plan][period].priceId!
}